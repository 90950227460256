<template>
  <div>
    <p>流程自动审核设置</p>
    <ul class="content">
      <li>
        <span class="red">*</span>审批提醒：
        <el-input-number v-model="form.autoMessageDays" :min="0" size="small" :controls="false" /> 天
        <p>流程超过多少天未处理，由系统自动汇总未处理的单据推送审批消息提醒。</p>
      </li>
      <li>
        <span class="red">*</span>待审核超过天数：
        <el-input-number v-model="form.autoRevokeDays" :min="0" size="small" :controls="false" /> 天
        <p>待审核超过多少天未审核，由系统自动对这些待审核数据进行撤回到拟稿处理。</p>
      </li>
      <li>
        <span class="red">*</span>流程待处理超过天数：
        <el-input-number v-model="form.autoDeleteDays" :min="0" size="small" :controls="false" /> 天
        <p>流程超过多少天未处理，由系统自动对这些流程进行删除处理。</p>
      </li>
      <li>
        <el-button size="small" type="primary" :loading="loading" @click="commit">保存</el-button>
      </li>
    </ul>

  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        autoDeleteDays: "30",
        autoMessageDays: "7",
        autoRevokeDays: "7",
      },
      loading:false
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$get('/platform-admin/sys/auto-audit/info').then(res => {
        this.form = res.data
      })
    },
    commit() {
       this.loading = true
      this.$post('/platform-admin/sys/auto-audit/set',this.form).then(res=>{
        this.$message.success('修改成功！');

      }).finally(()=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped >
.red {
  color: #db1313;
}
ul {
  padding-left: 100px;
  margin-top: 100px;
  line-height: 40px;
  li {
    margin-top: 50px;
  }
}
p {
  color: #999;
}
</style>
